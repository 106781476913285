import React from 'react';
import Header from './Header';
import Footer from './Footer';

import '../styles/global.css';

const Layout = ({ children }) => {
  return (
    <div style={{backgroundColor: '#fafbfe'}} className="p-1">
      <Header />
      <main className="container mx-auto px-1 min-h-screen">
        <div className="md:px-10">{children}</div>
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
