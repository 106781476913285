import React, { useState } from 'react';
import { Link } from 'gatsby';

const Header = () => {
  const [IsMenuHidden, setIsMenuHidden] = useState(true);
  const getMenuClasses = () => {
    return (
      (IsMenuHidden ? 'hidden' : 'block') + ' sm:flex sm:p-0 px-4 pt-2 pb-4'
    );
  };

  return (
    <header className="container max-w-6xl sm:flex sm:justify-between sm:items-center border-b-2 mb-6 mx-auto">
      <div className="flex items-center justify-between px-4 py-2">
        <div className="text-3xl">
          <Link to="/">
            <span className="text-indigo-500 font-bold">Ⲙ</span>ⲟⲏⲁ
            <span className="text-red-500 font-bold">ⲙ</span>ⲉⲇ
          </Link>
        </div>
        <div className="sm:hidden">
          <button type="button" onClick={() => setIsMenuHidden(!IsMenuHidden)}>
            <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
              {!IsMenuHidden ? (
                <path
                  fillRule="evenodd"
                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                />
              ) : (
                <path
                  fillRule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                />
              )}
            </svg>
          </button>
        </div>
      </div>
      <div className={getMenuClasses()}>
        <Link className="nav-item" to="/">
          <span role="img" aria-label="Home">
            🏠
          </span>{' '}
          Home
        </Link>
        <Link className="nav-item mt-2 sm:mt-0 sm:ml-10" to="/blog/">
          <span role="img" aria-label="Blog">
            ✍️
          </span>{' '}
          Blog
        </Link>
        <Link
          className="nav-item mt-2 sm:mt-0 sm:ml-10 sm:mr-4"
          to="/projects/"
        >
          <span role="img" aria-label="Projects">
            🛠️
          </span>{' '}
          Projects
        </Link>
      </div>
    </header>
  );
};

export default Header;
