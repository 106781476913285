import React from 'react';

const Footer = () => {
  return (
    <footer className="container flex justify-center items-center mx-auto mt-14 mb-5 p-1">
      <div>
        ©
        <a
          href="https://twitter.com/melbarchany"
          rel="noreferrer noopener"
          target="_blank"
          className="cursor-pointer underline ml-1"
        >
          Mohamed EL BARCHANY
        </a>
        <div className="flex justify-center mx-auto mt-1">
          <a
            href="https://twitter.com/melbarchany"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="twitter profil"
          >
            <svg
              className="icons"
              viewBox="0 0 612 612"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M612 116.258a250.714 250.714 0 01-72.088 19.772c25.929-15.527 45.777-40.155 55.184-69.411-24.322 14.379-51.169 24.82-79.775 30.48-22.907-24.437-55.49-39.658-91.63-39.658-69.334 0-125.551 56.217-125.551 125.513 0 9.828 1.109 19.427 3.251 28.606-104.326-5.24-196.835-55.223-258.75-131.174-10.823 18.51-16.98 40.078-16.98 63.101 0 43.559 22.181 81.993 55.835 104.479a125.556 125.556 0 01-56.867-15.756v1.568c0 60.806 43.291 111.554 100.693 123.104-10.517 2.83-21.607 4.398-33.08 4.398-8.107 0-15.947-.803-23.634-2.333 15.985 49.907 62.336 86.199 117.253 87.194-42.947 33.654-97.099 53.655-155.916 53.655-10.134 0-20.116-.612-29.944-1.721 55.567 35.681 121.536 56.485 192.438 56.485 230.948 0 357.188-191.291 357.188-357.188l-.421-16.253c24.666-17.593 46.005-39.697 62.794-64.861z" />
            </svg>
          </a>
          <a
            className="ml-5"
            href="https://github.com/melbarch"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="github profil"
          >
            <svg
              className="icons"
              viewBox="0 0 128 128"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipRule="evenodd">
                <path fill="none" d="M0 0h128v128H0z" />
                <path d="M63.996 1.333C28.656 1.333 0 30.099 0 65.591c0 28.384 18.336 52.467 43.772 60.965 3.2.59 4.368-1.394 4.368-3.096 0-1.526-.056-5.566-.088-10.927-17.804 3.883-21.56-8.614-21.56-8.614-2.908-7.421-7.104-9.397-7.104-9.397-5.812-3.988.44-3.907.44-3.907 6.42.454 9.8 6.622 9.8 6.622 5.712 9.819 14.98 6.984 18.628 5.337.58-4.152 2.236-6.984 4.064-8.59-14.212-1.622-29.152-7.132-29.152-31.753 0-7.016 2.492-12.75 6.588-17.244-.66-1.626-2.856-8.156.624-17.003 0 0 5.376-1.727 17.6 6.586 5.108-1.426 10.58-2.136 16.024-2.165 5.436.028 10.912.739 16.024 2.165 12.216-8.313 17.58-6.586 17.58-6.586 3.492 8.847 1.296 15.377.636 17.003 4.104 4.494 6.58 10.228 6.58 17.244 0 24.681-14.964 30.115-29.22 31.705 2.296 1.984 4.344 5.903 4.344 11.899 0 8.59-.08 15.517-.08 17.626 0 1.719 1.152 3.719 4.4 3.088C109.68 118.034 128 93.967 128 65.591c0-35.492-28.656-64.258-64.004-64.258" />
              </g>
            </svg>
          </a>
          <a
            className="ml-5"
            href="https://www.linkedin.com/in/elbarchany"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="linkedin profil"
          >
            <svg
              className="icons"
              viewBox="0 0 430.117 430.117"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M430.117 261.543V420.56h-92.188V272.193c0-37.271-13.334-62.707-46.703-62.707-25.473 0-40.632 17.142-47.301 33.724-2.432 5.928-3.058 14.179-3.058 22.477V420.56h-92.219s1.242-251.285 0-277.32h92.21v39.309c-.187.294-.43.611-.606.896h.606v-.896c12.251-18.869 34.13-45.824 83.102-45.824 60.673-.001 106.157 39.636 106.157 124.818zM52.183 9.558C20.635 9.558 0 30.251 0 57.463c0 26.619 20.038 47.94 50.959 47.94h.616c32.159 0 52.159-21.317 52.159-47.94-.606-27.212-20-47.905-51.551-47.905zM5.477 420.56h92.184V143.24H5.477v277.32z" />
            </svg>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
